import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../Context/AppContext";
import "./Questions.css";
import { getJobQuestions } from "./helper";

const AdditionalQuestions = ({
  setComponents,
  currentSubStep,
  setCurrentSubStep,
}) => {
  const { state, updateState } = useContext(AppContext);

  const [selectedJobQuestion, setSelectedJobQuestion] = useState(
    state?.problem?.jobItem?.category_name
  );
  const [jobsArray, setJobsArray] = useState();
  const [additionalQuestions, setAdditionalQuestions] = useState([]);

  useEffect(() => {
    if (state?.additionalInfo?.jobItemToBook) {
      updateState({
        ...state,
        isSubStepValid: additionalQuestions.length > 0 ? Boolean(state?.problem?.questions) : true,
      });
    } else {
      updateState({
        ...state,
        isSubStepValid: false,
      });
    }

    const problemItem = state?.problem?.jobItem;
    if(problemItem?.jobs?.length===1){
      if(problemItem?.jobs[0] === problemItem?.category_name){
         problemItem.is_job = true;
      }
    }
    if (!problemItem?.is_job) {
      const selectedTrade = state?.problem?.trade?.trade?.name;
      const selectedServiceType = state?.problem?.servicetype;
      const jobMappings = state?.organizationDetails?.jobs_mapping;
      const matchingTrade = jobMappings.find(
        (trade) => trade?.trade?.tradeName === selectedTrade
      );
      const matchingJobs = matchingTrade[selectedServiceType];

      let activeJobs = [];

      problemItem?.jobs?.forEach((job) => {
        const jobFound = matchingJobs.find((e) => e?.jobItem?.jobItem === job && e?.is_active);
        if (jobFound) {
          activeJobs.push(job);
        }
      });

      activeJobs = activeJobs.sort();
      setJobsArray(activeJobs);
    }

    if (state?.additionalInfo?.jobItemToBook) {
      const preservedJobItem =
        state?.additionalInfo?.jobItemToBook?.jobItem?.jobItem;
      setSelectedJobQuestion(preservedJobItem); // use state
    }
  }, [additionalQuestions]);

  useEffect(() => {
    let newComponents = 0;
    const isJob = state?.problem?.jobItem?.is_job;
    const hasAdditionalQuestions =
      state?.miscellaneous?.additionalQuestions?.length > 0;
    let dispatchFee =0
    if(state?.customUrlData?.dispatchFee){
      dispatchFee = state?.customUrlData?.dispatchFeeDetails.member_is_active?state.customUrlData.dispatchFeeDetails.member_dispatch_fee:state.customUrlData.dispatchFeeDetails.dispatch_fee;
    }
    else{
      dispatchFee = state?.clientInfo?.is_member===false?state?.additionalInfo?.jobItemToBook?.dispatch_fee:state?.additionalInfo?.jobItemToBook?.member_dispatch_fee ?? 0;
    }

    if (isJob && !hasAdditionalQuestions) {
      if (!dispatchFee || dispatchFee < 1) {
        newComponents = 3;
      } else {
        newComponents = 2;
      }
    }

    if (isJob && hasAdditionalQuestions) {
      if (!dispatchFee || dispatchFee < 1) {
        newComponents = 1;
      } else {
        newComponents = 0;
      }
    }

    if (!isJob) {
      if (dispatchFee === undefined || dispatchFee < 1) {
        newComponents = 1;
      } else {
        newComponents = 0;
      }
    }

    setComponents(newComponents);
  }, [state?.additionalInfo?.jobItemToBook]);

  useEffect(() => {
    setAdditionalQuestions([]);
    console.log(state?.problem?.servicetype)
    console.log(state?.problem?.jobItem?.category_name)
    console.log("selected job ")
    console.log(selectedJobQuestion)
    if (selectedJobQuestion) {
      let tempAdditionalQuestions = [];
	  const selectedTrade = state?.problem?.trade?.trade?.name;
	  const selectedServiceType = state?.problem?.servicetype;
  
      const jobQuestions = getJobQuestions(state, selectedJobQuestion);

      setAdditionalQuestions(jobQuestions);

      tempAdditionalQuestions = jobQuestions;

      const jobMappings = state?.organizationDetails?.jobs_mapping;
      const matchingTrade = jobMappings.find(
        (trade) => trade?.trade?.tradeName === selectedTrade
      );

      if (matchingTrade) {
        const matchingServiceTypeArray = matchingTrade[selectedServiceType];

        if (matchingServiceTypeArray) {
          const matchingJobItemObject = matchingServiceTypeArray.find(
            (serviceType) =>
              serviceType?.jobItem?.jobItem === selectedJobQuestion
          );

          if (matchingJobItemObject) {

            updateState({
              ...state,
              miscellaneous: {
                ...state?.miscellaneous,
                additionalQuestions: tempAdditionalQuestions,
              },
              additionalInfo: {
                ...state.additionalInfo,
                jobItemToBook: matchingJobItemObject,
              },
            });
          }
        }
      }
    }
  }, [selectedJobQuestion]);

  const handleJobSelection = (job) => {
    let newState = state;
    newState = {
      ...newState,
      problem: {
        ...newState.problem,
        questions: null,
      },
    };

    const jobQuestions = getJobQuestions(newState, job);

    if (!jobQuestions.length) {
      newState.isSubStepValid = true;
      updateState(newState);
      setSelectedJobQuestion(job);
      setTimeout(() => {
        setCurrentSubStep(currentSubStep + 1);
      }, 100);
    } else {
      newState.isSubStepValid = false;
      updateState(newState);
      setSelectedJobQuestion(job);
    }
  };

  const handleAdditionalQuestions = (
    additionalQuestion,
    selectedAdditionalOption,
  ) => {
    const stateValue = {
      ...state,
      problem: {
        ...state.problem,
        questions: {
          ...state.problem.questions,
          [additionalQuestion]: selectedAdditionalOption?.option,
        },
      },
    };

    updateState(stateValue);

    const moveToNextStep = Object.values(stateValue.problem.questions).length === additionalQuestions.length;
    
    moveToNextStep && setCurrentSubStep(currentSubStep + 1);
  };

  console.log(additionalQuestions)

  return (
    <div className="QuestionsList_otr">
      <div className="list_header_otr">
        <div className="bread_crumbs">
          <p className="Service_text">
            {state?.problem?.trade?.trade?.name} - {state?.problem?.servicetype}{" "}
            - {state?.problem?.jobItem?.category_name}
          </p>
        </div>
        {jobsArray && (
          <div>
            <div className="heading_icon_otr">
              <h6 className="header_heading">
                {state?.problem?.jobItem?.heading}
                <hr />
              </h6>
            </div>
            <div className="question_list_inr">
              {jobsArray?.map((job, optionIndex) => {
                return (
                  <button
                    key={optionIndex}
                    className={`questions_button ${
                      state?.additionalInfo?.jobItemToBook?.jobItem?.jobItem ===
                      job
                        ? "clicked"
                        : ""
                    }`}
                    onClick={() => handleJobSelection(job)}
                  >
                    <span className="button_text">{job}</span>
                  </button>
                );
              })}
            </div>
          </div>
        )}

        {additionalQuestions?.map((questionObj, questionIndex) => (
          <div className="">
            <div className="heading_icon_otr">
              <h6 className="header_heading">
                {questionObj?.question}
                <hr />
              </h6>
            </div>
            <div className="question_list_inr">
              {questionObj?.options?.map((option, index) => {
                return (
                  <button
                    key={index}
                    className={`questions_button ${
                      state?.problem?.questions?.[questionObj?.question] ===
                      option?.option
                        ? "clicked"
                        : ""
                    }`}
                    onClick={() =>
                      handleAdditionalQuestions(questionObj?.question, option)
                    }
                  >
                    <span className="button_text">{option?.option}</span>
                  </button>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdditionalQuestions;


// demo associated questions